<template>
  <v-container fluid  class="wrapper d-flex align-center">
    <!-- <v-layout class="centered"> -->
    <!-- <logo-header></logo-header> -->
    <!-- </v-layout> -->
    <v-layout class="centered">
      <v-flex xs12 sm12 md6 lg6>
        <v-card>
          <!-- <v-card-text class=""> -->
            <!-- <v-card-title class="white--text">OAuth</v-card-title> -->
          <!-- </v-card-text> -->
          <v-card-text>
            <div v-show="!error && code && provider">
              <v-progress-linear :indeterminate="true"></v-progress-linear>
              Verifying {{ provider }} code...
            </div>
            <div v-if="error">
              <div
                v-if="
                  error === 'registration restricted, contact administrator'
                "
              >
                <span class="text-h5">
                Registration at the moment is closed.
                </span>
                <p class="mt-5">
                  Contact system administrator or admins from our
                  <a
                    href="https://discord.com/invite/dkgN4vVNdm"
                    rel="noopener"
                    target="_blank"
                    >Discord Community</a
                  >
                  to get added to the early access users list.
                </p>
              </div>
              <v-alert v-else type="error" dense outlined>
                {{ error }}
              </v-alert>
            </div>
          </v-card-text>
          <v-card-actions v-if="error">
            <v-spacer> </v-spacer>
            <router-link :to="{ name: 'login' }">
              <v-btn outlined class="success--text">Login</v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style lang="stylus">
.centered {
    text-align: center;
    margin: auto;
    display: flex;
    // margin-top: 6rem
    justify-content: center;
  }
    .wrapper {
    height: 100%;
  }
</style>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      error: null,
      code: this.$route.query.code,
      state: this.$route.query.state,
      provider: this.$route.params.provider
    }
  },

  mounted() {
    if (!this.code) {
      return
    }

    this.$auth
      .load()
      .then(() => {
        if (this.$auth.check()) {
          return
        }

        axios.post(`oauth/${this.provider}?code=${this.code}&state=${this.state}`, null).then((response) => {
          this.error = null

          const token = response.data.token
          localStorage.setItem('auth_token_default', token)
          this.$auth.token(null, token, false)
          this.setCookie('rememberMe', true)

          this.$router.push({ name: 'home' })

        }).catch((error) => {
          console.log(error.response.data.error.message)
          this.error = error.response.data.error.message
        })
      })
  },

  methods: {
    setCookie: function (name, value, timeOffset) {
      var domain = window.location.hostname
      var expires = (new Date((new Date()).getTime() + timeOffset)).toUTCString()

      document.cookie = name + '=' + value + '; Expires=' + expires + '; Path=/; Domain=' + domain + ';'
    }
  }
}
</script>
